import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";
import { Helmet } from "react-helmet";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Layout from "../components/layout/layout";
import SliceZone from "../components/sliceZone";
import Seo from "../components/seo";

// import { FilterArrayByType } from "../utility";

const GeneralContentPage = ({ data, location }) => {
	const doc = data?.prismicGeneralContentPage?.data;
	if (!doc) return null;

	console.log(data);

	return (
		<Layout>
			<Seo
				title={doc?.meta_title}
				description={doc?.meta_description}
				ogImage={doc?.og_image?.url}
				location={location}
				content={doc?.content}
			/>

			{/* <HeroImage
				image={getImage(doc.hero_background_image)}
				title={doc.hero_title}
			/> */}
			<div className="">
				<div className="row">
					<SliceZone sliceZone={doc.body} />
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query GeneralContentPageQuery($uid: String) {
		prismicGeneralContentPage(uid: { eq: $uid }) {
			data {
				og_image {
					url
				}
				meta_description
				meta_title
				content
				body {
					... on PrismicGeneralContentPageDataBodyGeneralContentWithHeadline {
						id
						slice_type
						primary {
							general_content_headline
						}
						items {
							general_content_content {
								richText
							}
							general_content_button_text
							general_content_button_link {
								id
								link_type
								url
								target
							}
						}
					}
					... on PrismicGeneralContentPageDataBodyCallToAction {
						id
						slice_type
						primary {
							call_to_action_background_image {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
								)
								url
							}
							call_to_action_title {
								richText
							}
							call_to_action_description
							call_to_action_button_text
							call_to_action_button_url {
								url
								link_type
								id
								type
								target
							}
							call_to_action_button_color
						}
					}
				}
			}
			_previewable
		}
	}
`;

export default withPrismicPreview(GeneralContentPage);

GeneralContentPage.propTypes = {
	location: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};
